import * as schemaItemTypes from './schemaItemTypes';
// import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

const linkedProperties = {
  BUILDINGS: 'buildings',
  PROJECTS: 'projects',
  UNITS: 'units',
  ROOMS: 'rooms',
  PAYMENT_TYPE: 'paymentTypes',
};

/**
 * @type {Array<import('./schema').default>}
 */

export const schemaBaseInfo = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'label.title',
        prop: 'title',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.category',
        prop: 'category',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.type',
        prop: 'type',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.description',
        prop: 'description',
      },
    ],
  },
  {
    type: schemaItemTypes.REQUEST_ICON,
    label: 'label.cover_icon',
    prop: 'icon',
    rules: [rules.REQUIRED],
  },
];

export const schemaRequestForm = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.REQUEST_FIELDS_LIST,
        prop: 'fields',
        size: 6,
      },
    ],
  },
];

export const schemaDetails = [
  {
    label: 'label.project',
    type: schemaItemTypes.TITLED_SECTION,
    removeMT: true,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'label.projects',
            prop: linkedProperties.PROJECTS,
            type: schemaItemTypes.MANY_PROJECTS,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'label.buildings',
            prop: linkedProperties.BUILDINGS,
            type: schemaItemTypes.MANY_BUILDINGS,
            size: 3,
          },
          {
            label: 'label.units',
            prop: linkedProperties.UNITS,
            type: schemaItemTypes.MANY_UNITS,
            size: 3,
          },
          {
            label: 'label.rooms',
            prop: linkedProperties.ROOMS,
            type: schemaItemTypes.MANY_ROOMS,
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'label.roles',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'requests.pending',
            prop: 'pendingRoles',
            type: schemaItemTypes.MANY_ITEMS,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.in_progress',
            prop: 'inProgressRoles',
            type: schemaItemTypes.MANY_ITEMS,
            rules: [rules.REQUIRED],
            size: 3,
          },
          {
            label: 'requests.ready',
            prop: 'completedRoles',
            type: schemaItemTypes.MANY_ITEMS,
            rules: [rules.REQUIRED],
            size: 3,
          },
        ],
      },
    ],
  },
  {
    label: 'label.clients_type',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        label: 'label.days',
        prop: 'clientType',
        type: schemaItemTypes.REQUEST_CLIENTS_TYPE,
      },
    ],
  },
  {
    label: 'label.deadline',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            label: 'label.days',
            prop: 'deadline',
            type: schemaItemTypes.NUMBER,
          },
        ],
      },
    ],
  },
  // {
  //   label: 'label.payment',
  //   type: schemaItemTypes.TITLED_SECTION,
  //   children: [
  //     {
  //       type: schemaItemTypes.ROW,
  //       children: [
  //         {
  //           type: schemaItemTypes.REQUEST_PAYMENT,
  //           prop: 'paymentRequired',
  //           size: 6,
  //         },
  //       ],
  //     },
  //   ],
  // },
];
