<template>
  <div>
    <!-- @todo add edit route -->
    <entity-header
      class="mb-10"
      :title="request.title"
      :is-archived="request.isArchived"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdateRequest"
      :loading="isLoading"
      @archive="archive"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder
      v-else
      :schema-list="[$options.schemaBaseInfo, $options.schemaRequestForm, $options.schemaDetails]"
      :data="request"
      is-tabs
      :tabs="tabs"
      :active-tab="tab"
      @change-tab="changeTab"
    />
  </div>
</template>
<script>
// Constants
import { TAB_BASE_INFO, TAB_REQUEST_FORM, TAB_DETAILS } from '@/constants/tabs';
import { REQUESTS, REQUESTS_ARCHIVE, REQUESTS_EDIT } from '@/constants/routes';

import { schemaBaseInfo, schemaRequestForm, schemaDetails } from '@/schemas/request.view.schema';

// Components
import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/EntityHeader.vue';

// Services
import requestsService from '@/services/requests';
// import analyticsService from '@/services/analytics';
import { normalizeRequestDetailed } from '@/services/serializations';

export default {
  name: 'RequestDetailed',

  components: { ViewBuilder, EntityHeader },

  props: {},

  data() {
    return {
      isLoading: false,
      request: {},
      id: this.$route.params.id,
      tab: 0,
      tabs: [
        {
          label: 'tab.base_info',
          tab: TAB_BASE_INFO,
        },
        {
          label: 'tab.request_form',
          tab: TAB_REQUEST_FORM,
        },
        {
          label: 'tab.details',
          tab: TAB_DETAILS,
        },
      ],
    };
  },

  computed: {
    canUpdateRequest() {
      return true;
      // return this.$can(UPDATE, PROJECTS_SUBJECT);
    },

    backRoute() {
      const routeName = this.request.isArchived ? REQUESTS_ARCHIVE : REQUESTS;
      return this.$route.params.prevPage || { name: routeName };
    },

    editRoute() {
      return { name: REQUESTS_EDIT, params: { id: this.id } };
    },
  },

  mounted() {
    // analyticsService.track(REQUESTS);
    this.fetchRequest();
  },

  methods: {
    archive() {
      // analyticsService.track(this.request.isArchived ? UNARCHIVE_PROJECT : ARCHIVE_PROJECT);
      requestsService.archiveRequest(this.id, !this.request.isArchived).then(() => {
        this.request.isArchived = !this.request.isArchived;
      });
    },
    fetchRequest() {
      this.isLoading = true;

      requestsService
        .getRequestById(this.$route.params.id)
        .then(request => {
          this.request = normalizeRequestDetailed(request);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  schemaBaseInfo,
  schemaRequestForm,
  schemaDetails,
};
</script>
